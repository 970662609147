import React, { Fragment } from "react"
import TestimonialSectionWrapper, {
  ClientName,
  ImageWrapper,
  RoundWrapper,
  TextWrapper,
} from "./testimonials.style"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import GlideCarousel from "../../components/GlideCarousel"
import GlideSlide from "../../components/GlideCarousel/glideSlide"
import Heading from "../../components/Heading"
import { Icon } from "react-icons-kit"
import IconLeft from "../../common/assets/image/alpacked/home/left.svg"
import IconRight from "../../common/assets/image/alpacked/home/right.svg"
import Image from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"
import { linkedin } from "react-icons-kit/fa/linkedin"

const TestimonialSection = ({
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
  content,
  hideButton,
}) => {
  const { primary, items } = content
  // Glide js options
  const glideOptions = {
    type: "carousel",
    autoplay: false,
    perView: 1,
  }

  return (
    <TestimonialSectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
        />
        <GlideCarousel
          options={glideOptions}
          buttonWrapperStyle={btnWrapperStyle}
          nextButton={
            <Button
              icon={<img loading="lazy" src={IconRight} alt="next" />}
              variant="textButton"
              aria-label="next"
              {...btnStyle}
            />
          }
          prevButton={
            <Button
              icon={<img loading="lazy" src={IconLeft} alt="previous" />}
              variant="textButton"
              aria-label="prev"
              {...btnStyle}
            />
          }
        >
          <Fragment>
            {items.map((item, index) => (
              <GlideSlide key={index}>
                <Fragment>
                  <ImageWrapper>
                    <RoundWrapper>
                      <Image
                        fadeIn={false}
                        loading="lazy"
                        fluid={item.image.localFile?.childImageSharp.fluid}
                        alt="Client Image"
                        className="image"
                      />
                    </RoundWrapper>
                    <a
                      aria-label="linkedin"
                      href={item.linkedin_url.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={linkedin} />
                    </a>
                  </ImageWrapper>
                  <TextWrapper>
                    <Text content={item.testimonial} {...commentStyle} />
                    <ClientName>
                      <Heading content={item.name.text} {...nameStyle} />
                      <Heading
                        content={item.occupation}
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>
                </Fragment>
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
        {!hideButton && (
          <Link to="/reviews/" className="more">
            <Button className="primary" title="OTHER REVIEWS" />
          </Link>
        )}
      </Container>
    </TestimonialSectionWrapper>
  )
}

// TestimonialSection style props
TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  content: PropTypes.object,
  hideButton: PropTypes.bool,
}

// TestimonialSection default style
TestimonialSection.defaultProps = {
  // client comment style
  commentStyle: {
    color: "#000",
    fontWeight: "300",
    fontSize: ["16px", "18px", "18px", "18px"],
    lineHeight: ["28px", "30px"],
    mb: "32px",
  },
  // client name style
  nameStyle: {
    as: "h2",
    color: "#000",
    fontWeight: "700",
    fontSize: ["18px", "20px", "22px"],
    lineHeight: "30px",
    mb: ["0px", "0px"],
  },
  // client designation style
  designationStyle: {
    as: "h3",
    color: "#000",
    fontWeight: "300",
    fontSize: ["14px", "16px"],
    lineHeight: "20px",
    mb: ["40px", "0px"],
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: "absolute",
    bottom: "0",
  },
  // next / prev btn style
  btnStyle: {
    minWidth: "auto",
    minHeight: "auto",
    ml: "1px",
    fontSize: "18px",
    color: "#fff",
    backgroundColor: "#21AAD4",
    px: ["15px", "19.5px"],
    py: ["15px", "25px"],
    borderRadius: "0px",
  },
}

export default TestimonialSection
